var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { fromEvent as observableFromEvent, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { ExperienceTypeahead } from "./common/typeaheads/experienceTypeahead";
import $ from "./common/jquery";
import { DateRangePicker } from "./common/dateRangePicker";
import { ScreenSize } from "./common/utils/ScreenSize";
import { DatepickerUtils } from "./datepickerUtils";
import { AjaxService } from "./common/ajax";
import { ClickStream } from "./clickstream";
var SearchMobileModal = (function () {
    function SearchMobileModal() {
        var _this = this;
        this.searchMobileModalData = {
            initialTypeaheadInputData: null,
            typeaheadInputData: null,
            dateInputValue: { startDate: '', endDate: '' },
            searchModalInitialDateInputValue: { startDate: '', endDate: '' },
            searchHeaderBarDateInputValue: { startDate: '', endDate: '' },
        };
        this.resetDatesInPickerAndModalData = function () {
            _this.searchMobileModalData.dateInputValue = __assign({}, _this.searchMobileModalData.searchHeaderBarDateInputValue);
            _this.searchMobileModalData.searchModalInitialDateInputValue = __assign({}, _this.searchMobileModalData.searchHeaderBarDateInputValue);
            _this.setDatesInPickerToInitialDateInputValue();
        };
        this.setDatesInPickerToInitialDateInputValue = function () {
            var _a = _this.searchMobileModalData.searchModalInitialDateInputValue, startDate = _a.startDate, endDate = _a.endDate;
            _this.searchMobileModalData.dateInputValue = {
                endDate: endDate,
                startDate: startDate
            };
            if (startDate !== "") {
                var startD = new Date(startDate);
                startD.setHours(0);
                var endD = new Date(endDate);
                endD.setHours(0);
                _this.picker.update({ startDate: startD, endDate: endD });
                _this.$datePickerButtonText.text(_this.picker.getDateRangeAsString());
                _this.$datePickerButtonText.addClass("mobile-date-range-chosen-text");
            }
            else {
                _this.restoreDatePlaceholderText();
                _this.picker.clear();
            }
        };
        this.updateSearchModal = function () {
            var hasTypeaheadSearch = _this.searchMobileModalData.typeaheadInputData !== null
                && _this.searchMobileModalData.typeaheadInputData.displayText.length > 0;
            _this.$searchPickerButtonText.toggleClass("d-none", !hasTypeaheadSearch);
            _this.$searchPickerButtonTextPlaceholder.toggleClass("d-none", hasTypeaheadSearch);
            if (hasTypeaheadSearch) {
                _this.$searchPickerButtonText.html(_this.searchMobileModalData.typeaheadInputData.displayText);
            }
        };
        this.updateSearchModalDates = function () {
            _this.searchMobileModalData.searchHeaderBarDateInputValue = {
                startDate: _this.$searchMobileDateRange.attr("data-startdate") || "",
                endDate: _this.$searchMobileDateRange.attr("data-enddate") || "",
            };
            _this.searchMobileModalData.searchModalInitialDateInputValue = __assign({}, _this.searchMobileModalData.searchHeaderBarDateInputValue);
            _this.setDatesInPickerToInitialDateInputValue();
        };
        this.restoreDatePlaceholderText = function () {
            _this.$datePickerButtonText.removeClass("mobile-date-range-chosen-text");
            _this.$datePickerButtonText.text(_this.picker.pickerInput.attr("placeholder"));
        };
        this.handleOnDatePickerOpen = function () {
            var dateRange = _this.picker.getSelectedDate();
            if (dateRange.startDate !== null && dateRange.endDate !== null) {
                _this.searchMobileModalData.searchModalInitialDateInputValue = {
                    endDate: (dateRange && DatepickerUtils.formatDateToYMDString(dateRange.endDate)) || "",
                    startDate: (dateRange && DatepickerUtils.formatDateToYMDString(dateRange.startDate)) || "",
                };
            }
        };
        this.handleOnDatePickerApply = function () {
            var dateRange = _this.picker.getSelectedDate();
            if (dateRange.startDate !== null && dateRange.endDate !== null) {
                _this.searchMobileModalData.dateInputValue = {
                    endDate: (dateRange && DatepickerUtils.formatDateToYMDString(dateRange.endDate)) || "",
                    startDate: (dateRange && DatepickerUtils.formatDateToYMDString(dateRange.startDate)) || "",
                };
                _this.$datePickerButtonText.text(_this.picker.getDateRangeAsString());
                _this.$datePickerButtonText.addClass("mobile-date-range-chosen-text");
            }
            else {
                _this.restoreDatePlaceholderText();
            }
        };
        this.handleOnDatePickerReset = function () {
            _this.searchMobileModalData.dateInputValue = {
                endDate: "",
                startDate: "",
            };
            _this.searchMobileModalData.searchModalInitialDateInputValue = {
                endDate: "",
                startDate: "",
            };
            _this.restoreDatePlaceholderText();
        };
        this.updateDateLabelInHeader = function (startDate) {
            if (startDate !== "") {
                var dateString = _this.picker.getDateRangeAsString();
                _this.$searchMobileHeaderDateDisplay.text(dateString);
                _this.$searchMobileHeaderDateDisplay.addClass("mobile-header-date-selected");
            }
            else {
                _this.$searchMobileHeaderDateDisplay.text(_this.picker.pickerInput.attr("placeholder"));
                _this.$searchMobileHeaderDateDisplay.removeClass("mobile-header-date-selected");
            }
        };
        this.trackSearch = function (defaultDate) {
            ClickStream.postClickStreamWithTrackingArguments({
                servletName: ClickStream.getServlet(),
                pageAction: "search",
                pageProperties: {
                    searchId: _this.$typeaheadMobileModal.find(".eagle-header-search").data("search-id"),
                    searchTerm: _this.searchMobileModalData.typeaheadInputData.displayText,
                    defaultDate: defaultDate
                }
            });
        };
        this.$typeaheadMobileModalInput = $("#typeaheadMobileModalInput");
        if (this.$typeaheadMobileModalInput.length >= 1) {
            this.$searchPickerButton = $(".eagle-header-search-mobile-page-button");
            this.$searchPickerButtonText = $("#searchPickerButtonText");
            this.$searchPickerButtonTextPlaceholder = $("#searchPickerButtonTextPlaceholder");
            this.$searchMobileModalShow = $(".search-mobile-modal-show");
            this.$searchMobileModal = $("#searchMobileModal");
            this.$typeaheadMobileModal = $("#typeaheadMobileModal");
            this.$typeaheadMobileModalShow = $(".typeahead-mobile-modal-show");
            this.$typeaheadMobileModalResults = this.$typeaheadMobileModal.find(".typeahead-results");
            this.$searchMobileHeaderBar = $(".search-mobile-modal-show .search-picker-button-text");
            this.$searchMobileHeaderDateDisplay = $("#searchMobileHeaderDateDisplay");
            this.$searchMobileDateRange = $("#searchMobileDateRange");
            this.$typeaheadMobileModalInputClearButton = $("#typeaheadMobileModalInputClearButton");
            this.$searchButton = $(".mobile-search-button");
            this.$datePickerButton = $(".mobile-date-range-button");
            this.$datePickerButtonText = $(".mobile-date-range-button-text");
            this.$mobileSearchWrapper = $(".eagle-header-search-mobile");
            this.$emptySearchTermPlaceholder = $("#emptySearchTermPlaceholder");
            this.$seeAllResultsNearby = $(".see-all-results-nearby");
            this.experienceTypeaheadController = new ExperienceTypeahead(window, this.$typeaheadMobileModal, { preventStoringRecentSearches: true });
            this.ajaxService = new AjaxService();
            this.bootstrap();
        }
        this.dateSetObs = new Subject();
    }
    SearchMobileModal.prototype.getDatesSetObservable = function () {
        return this.dateSetObs;
    };
    SearchMobileModal.prototype.bootstrap = function () {
        var _this = this;
        if ($("#dateRangePicker").length > 0) {
            $(".search-date-filter").removeClass("d-none");
        }
        observableFromEvent(this.$searchMobileModalShow, "click")
            .subscribe(function (event) {
            if (!_this.$emptySearchTermPlaceholder.length) {
                _this.searchMobileModalData.typeaheadInputData = {
                    displayText: _this.$searchMobileHeaderBar.text(),
                    url: _this.$searchMobileHeaderBar.data("requestUri"),
                    isTtdPage: _this.$searchMobileHeaderBar.data("isTtdPage")
                };
            }
            _this.searchMobileModalData.initialTypeaheadInputData = __assign({}, _this.searchMobileModalData.typeaheadInputData);
            _this.updateSearchModal();
            _this.updateSearchModalDates();
            ClickStream.postClickStreamWithTrackingArguments({
                servletName: ClickStream.getServlet(),
                pageAction: "open_mobile_search_modal",
            });
            _this.$searchMobileModal.modal("show");
            event.stopPropagation();
        });
        var $mobileDateRangePicker = $("#mobileDateRangePicker");
        if ($mobileDateRangePicker.length) {
            this.picker = new DateRangePicker($mobileDateRangePicker, $mobileDateRangePicker, null, {
                allowApplyWithoutDates: false,
                screenSizeToShowModal: ScreenSize.MD,
            });
            this.updateSearchModalDates();
            this.updateDateLabelInHeader(this.searchMobileModalData.searchModalInitialDateInputValue.startDate);
        }
        observableFromEvent(this.$typeaheadMobileModalShow, "click")
            .subscribe(function (event) {
            if (_this.searchMobileModalData.typeaheadInputData) {
                _this.$typeaheadMobileModalInput.val(_this.searchMobileModalData.typeaheadInputData.displayText);
            }
            _this.updateRecentSearchDisplay();
            _this.$typeaheadMobileModal.modal("show");
            _this.updateTypeaheadClearButton();
            _this.$typeaheadMobileModalInput.trigger("focus");
            _this.$typeaheadMobileModalInput.trigger("change");
            if (_this.$typeaheadMobileModalInput.val()) {
                _this.experienceTypeaheadController.updateSearchInput(_this.$typeaheadMobileModalInput.val().toString());
            }
            if (!_this.experienceTypeaheadController.isNearbySearch()) {
                _this.experienceTypeaheadController.showFocusedFlyout();
            }
            ClickStream.postClickStreamWithTrackingArguments({
                pageAction: "click_search_open",
                servletName: ClickStream.getServlet()
            });
            event.stopPropagation();
        });
        observableFromEvent(this.$typeaheadMobileModalInput, "keyup")
            .pipe(filter(function (event) { return event.key === "Enter"; }))
            .subscribe(function (event) {
            _this.searchMobileModalData.typeaheadInputData = _this.experienceTypeaheadController.createTextSearchData(_this.$typeaheadMobileModalInput.val().toString());
            _this.updateSearchModal();
            _this.$typeaheadMobileModal.modal("hide");
            event.stopPropagation();
        });
        observableFromEvent(this.$typeaheadMobileModalInput, "input")
            .subscribe(function (event) {
            _this.updateTypeaheadClearButton();
            event.stopPropagation();
        });
        observableFromEvent(this.$typeaheadMobileModalInputClearButton, "click")
            .subscribe(function (event) {
            _this.$typeaheadMobileModalInput.val("");
            _this.updateTypeaheadClearButton();
            _this.$typeaheadMobileModalInput.trigger("focus");
            _this.experienceTypeaheadController.updateSearchInput("");
            event.stopPropagation();
        });
        this.experienceTypeaheadController
            .getSearchObservable()
            .subscribe(function (data) {
            _this.searchMobileModalData.typeaheadInputData = data;
            _this.updateSearchModal();
            _this.$typeaheadMobileModal.modal("hide");
        });
        observableFromEvent(this.$datePickerButton, "click")
            .subscribe(function (event) {
            _this.picker.open();
            event.stopPropagation();
        });
        this.picker.getApplyObservable().subscribe(function () { return _this.handleOnDatePickerApply(); });
        this.picker.getOpenObservable().subscribe(function () { return _this.handleOnDatePickerOpen(); });
        this.picker.getResetObservable().subscribe(function () { return _this.handleOnDatePickerReset(); });
        observableFromEvent($mobileDateRangePicker.find(".close"), "click").subscribe(function (event) {
            _this.setDatesInPickerToInitialDateInputValue();
        });
        $(this.$mobileSearchWrapper).on("click", ".picker__holder", function () {
            _this.setDatesInPickerToInitialDateInputValue();
        });
        $(this.$searchMobileModal).on("hidden.bs.modal", function () {
            _this.resetDatesInPickerAndModalData();
        });
        $(this.$typeaheadMobileModal).on("hidden.bs.modal", function () {
            $("body").addClass("modal-open");
        });
        observableFromEvent(this.$typeaheadMobileModalResults, "touchstart")
            .subscribe(function () {
            _this.$typeaheadMobileModalInput.blur();
        });
        observableFromEvent(this.$searchButton, "click").subscribe(function (event) {
            if (_this.$searchPickerButtonText.text() ===
                _this.$searchPickerButtonTextPlaceholder.text()) {
                _this.$searchPickerButton.addClass("warning");
                return;
            }
            _this.$searchPickerButton.removeClass("warning");
            var urlToGoTo = _this.searchMobileModalData.typeaheadInputData.url;
            _this.experienceTypeaheadController.storeRecentSearchData(_this.searchMobileModalData.typeaheadInputData);
            if (_this.searchMobileModalData.dateInputValue.startDate
                !== _this.searchMobileModalData.searchHeaderBarDateInputValue.startDate ||
                _this.searchMobileModalData.dateInputValue.endDate
                    !== _this.searchMobileModalData.searchHeaderBarDateInputValue.endDate) {
                _this.ajaxService
                    .postJSONSafe("/orion/rest/dates", _this.searchMobileModalData.dateInputValue)
                    .subscribe(function () {
                    _this.trackSearch(null);
                    if (_this.searchMobileModalData.typeaheadInputData.url === _this.searchMobileModalData.initialTypeaheadInputData.url &&
                        _this.searchMobileModalData.typeaheadInputData.isTtdPage === true) {
                        if ($('#aggregateFilterContents').length) {
                            var startDate = null;
                            var endDate = null;
                            if (_this.searchMobileModalData.dateInputValue.startDate !== '') {
                                startDate = new Date(_this.searchMobileModalData.dateInputValue.startDate);
                                startDate.setHours(0);
                            }
                            if (_this.searchMobileModalData.dateInputValue.endDate !== '') {
                                endDate = new Date(_this.searchMobileModalData.dateInputValue.endDate);
                                endDate.setHours(0);
                            }
                            _this.dateSetObs.next({ startDate: startDate, endDate: endDate });
                        }
                        _this.updateDateLabelInHeader(_this.searchMobileModalData.dateInputValue.startDate);
                        _this.$searchMobileDateRange.attr('data-startDate', _this.searchMobileModalData.dateInputValue.startDate);
                        _this.$searchMobileDateRange.attr('data-endDate', _this.searchMobileModalData.dateInputValue.endDate);
                        _this.$searchMobileModal.modal("hide");
                    }
                    else if (urlToGoTo) {
                        window.location.href = urlToGoTo;
                    }
                });
            }
            else {
                _this.trackSearch(_this.searchMobileModalData.searchHeaderBarDateInputValue.startDate + "-" + _this.searchMobileModalData.searchHeaderBarDateInputValue.endDate);
                if (urlToGoTo) {
                    window.location.href = urlToGoTo;
                }
                else {
                    _this.$searchMobileModal.modal("hide");
                }
            }
        });
        observableFromEvent(this.$seeAllResultsNearby, "click")
            .subscribe(function () { return _this.$typeaheadMobileModal.modal('hide'); });
    };
    SearchMobileModal.prototype.updateTypeaheadClearButton = function () {
        this.$typeaheadMobileModalInputClearButton.toggleClass("d-none", this.$typeaheadMobileModalInput.val().toString() === "");
    };
    SearchMobileModal.prototype.updateRecentSearchDisplay = function () {
        this.experienceTypeaheadController.displayRecentSearches(this.$typeaheadMobileModalInput.val().toString() === "");
    };
    return SearchMobileModal;
}());
export { SearchMobileModal };
