import { ClickStream } from "../ts/clickstream";
import { ObjectUtils } from "../ts/common/objectUtils";
import lottie from 'lottie-web/build/player/lottie_light';
import JsonHamburgerClosing from '../hamburger-closing.json';
import $ from "../ts/common/jquery";
import { SearchMobileModal } from "../ts/searchMobileModal";
var SECONDARY_MENU_STATE;
(function (SECONDARY_MENU_STATE) {
    SECONDARY_MENU_STATE["OPEN"] = "open";
    SECONDARY_MENU_STATE["CLOSING"] = "closing";
})(SECONDARY_MENU_STATE || (SECONDARY_MENU_STATE = {}));
var GlobalNavMobile = (function () {
    function GlobalNavMobile() {
        this.isL2TrackingInitialized = false;
        this.mobileGlobalNavHeader = GlobalNavMobile.getGlobalNavHeader();
        this.globalNavSideOverlayElement = this.mobileGlobalNavHeader && this.getGlobalNavSideOverlay();
        this.globalNavSideBarElement = this.mobileGlobalNavHeader && this.getGlobalNavSideBar();
        this.globalNavSecondaryOverlays = this.mobileGlobalNavHeader && this.getGlobalNavSecondaryOverlays();
        this.avatarButton = $('.global-nav-header-avatar');
        this.animation = lottie.loadAnimation({
            autoplay: false,
            loop: false,
            animationData: JsonHamburgerClosing,
            renderer: 'svg',
            container: document.getElementById("hamburger-animation")
        });
        this.animation.setSpeed(6);
        if (this.mobileGlobalNavHeader && this.globalNavSideOverlayElement && this.globalNavSideBarElement) {
            this.setupOverlayAnimation();
            this.setupGlobalNavSeeMore();
        }
        new SearchMobileModal();
    }
    GlobalNavMobile.prototype.setupOverlayAnimation = function () {
        var _this = this;
        var openMenuIcon = this.mobileGlobalNavHeader.querySelector(GlobalNavMobile.OPEN_MENU_ICON);
        var isMenuOpen = false;
        this.globalNavSideBarElement.addEventListener("click", function (evt) {
            evt.stopPropagation();
        });
        this.globalNavSideOverlayElement.addEventListener("click", function () {
            document.body.classList.remove("js-body-no-scroll");
            _this.globalNavSideOverlayElement.setAttribute("style", "opacity: 0");
        });
        openMenuIcon.addEventListener("click", function () {
            if (isMenuOpen) {
                _this.playHamburgerAnimation(-1);
                document.body.classList.remove("js-body-no-scroll");
                _this.globalNavSideOverlayElement.setAttribute("style", "opacity: 0");
                _this.avatarButton.css("visibility", "visible");
                _this.cleanupOverlay();
                _this.postHamburgerClickstream("close_header_nav", openMenuIcon);
                isMenuOpen = false;
            }
            else {
                _this.playHamburgerAnimation(1);
                document.body.classList.add("js-body-no-scroll");
                _this.globalNavSideOverlayElement.classList.add("d-block");
                _this.globalNavSideOverlayElement.classList.add("js-overlay-fade-in");
                _this.avatarButton.css("visibility", "hidden");
                _this.initializeL2Tracking();
                _this.postHamburgerClickstream("open_header_nav", openMenuIcon);
                isMenuOpen = true;
            }
        });
        this.globalNavSideOverlayElement.addEventListener("transitionend", function (evt) {
            if (evt.target === _this.globalNavSideOverlayElement) {
                _this.cleanupOverlay();
            }
        });
        this.globalNavSecondaryOverlays.forEach(function (element) {
            element.addEventListener("transitionend", function (evt) {
                evt.stopPropagation();
                if (element.getAttribute(GlobalNavMobile.SECONDARY_MENU_STATE_ATTRIBUTE) === SECONDARY_MENU_STATE.CLOSING) {
                    element.removeAttribute(GlobalNavMobile.SECONDARY_MENU_STATE_ATTRIBUTE);
                }
            });
        });
        this.mobileGlobalNavHeader.querySelectorAll(GlobalNavMobile.GLOBAL_NAV_SECONDARY_HEADER_CHEVRON).forEach(function (element, index) {
            element.addEventListener("click", function (evt) {
                evt.stopPropagation();
                _this.globalNavSecondaryOverlays.item(index)
                    .setAttribute(GlobalNavMobile.SECONDARY_MENU_STATE_ATTRIBUTE, SECONDARY_MENU_STATE.CLOSING);
            });
        });
        this.mobileGlobalNavHeader.querySelectorAll(GlobalNavMobile.GLOBAL_NAV_TOP_MENU_BUTTON).forEach(function (element, index) {
            element.addEventListener("click", function (evt) {
                evt.stopPropagation();
                _this.globalNavSecondaryOverlays.item(index)
                    .setAttribute(GlobalNavMobile.SECONDARY_MENU_STATE_ATTRIBUTE, SECONDARY_MENU_STATE.OPEN);
            });
        });
    };
    GlobalNavMobile.prototype.postHamburgerClickstream = function (action, element) {
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: action,
            servletName: ClickStream.getServlet(),
            pageProperties: ObjectUtils.stringToObject(element.getAttribute("data-action-page-properties")),
        });
    };
    GlobalNavMobile.prototype.initializeL2Tracking = function () {
        var _this = this;
        if (!this.isL2TrackingInitialized) {
            this.isL2TrackingInitialized = true;
            this.mobileGlobalNavHeader.querySelectorAll(".global-nav-overlay-secondary-category").forEach(function (element) {
                _this.trackL2WithIntersector(element);
            });
        }
    };
    GlobalNavMobile.prototype.playHamburgerAnimation = function (direction) {
        this.animation.setDirection(direction);
        this.animation.play();
    };
    GlobalNavMobile.prototype.cleanupOverlay = function () {
        this.globalNavSecondaryOverlays.forEach(function (element) { return element.removeAttribute(GlobalNavMobile.SECONDARY_MENU_STATE_ATTRIBUTE); });
        this.mobileGlobalNavHeader.querySelectorAll(GlobalNavMobile.GLOBAL_NAV_SECONDARY_OVERLAY_CATEGORIES)
            .forEach(function (element) { return element.setAttribute(GlobalNavMobile.SECONDARY_MENU_EXPANDED_ATTRIBUTE, "false"); });
        this.globalNavSideOverlayElement.classList.remove("d-block");
        this.globalNavSideOverlayElement.classList.remove("js-overlay-fade-in");
        this.globalNavSideOverlayElement.removeAttribute("style");
    };
    GlobalNavMobile.getGlobalNavHeader = function () {
        return document.getElementById("mobileGlobalNavHeader");
    };
    GlobalNavMobile.prototype.getGlobalNavSideOverlay = function () {
        return this.mobileGlobalNavHeader.querySelector(GlobalNavMobile.GLOBAL_NAV_OVERLAY);
    };
    GlobalNavMobile.prototype.getGlobalNavSideBar = function () {
        return this.mobileGlobalNavHeader.querySelector(GlobalNavMobile.GLOBAL_NAV_SIDEBAR);
    };
    GlobalNavMobile.prototype.getGlobalNavSecondaryOverlays = function () {
        return this.mobileGlobalNavHeader.querySelectorAll(GlobalNavMobile.GLOBAL_NAV_SECONDARY_OVERLAYS);
    };
    GlobalNavMobile.prototype.setupGlobalNavSeeMore = function () {
        this.mobileGlobalNavHeader.querySelectorAll(GlobalNavMobile.SEE_MORE_CONTAINER).forEach(function (element) {
            element.addEventListener("click", function () {
                if (element.parentElement.getAttribute(GlobalNavMobile.SECONDARY_MENU_EXPANDED_ATTRIBUTE) === "true") {
                    element.parentElement.setAttribute(GlobalNavMobile.SECONDARY_MENU_EXPANDED_ATTRIBUTE, "false");
                }
                else {
                    element.parentElement.setAttribute(GlobalNavMobile.SECONDARY_MENU_EXPANDED_ATTRIBUTE, "true");
                }
            });
        });
    };
    GlobalNavMobile.prototype.trackL2WithIntersector = function (element) {
        var intersectionObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    ClickStream.postClickStreamWithTrackingArguments({
                        servletName: ClickStream.getServlet(),
                        pageAction: "view_header_nav_links",
                        pageProperties: ObjectUtils.stringToObject(entry.target.getAttribute("data-action-page-properties")),
                    });
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.3 });
        intersectionObserver.observe(element);
    };
    GlobalNavMobile.OPEN_MENU_ICON = ".mobile-menu";
    GlobalNavMobile.GLOBAL_NAV_SIDEBAR = ".global-nav-sidebar";
    GlobalNavMobile.GLOBAL_NAV_OVERLAY = ".global-nav-overlay";
    GlobalNavMobile.GLOBAL_NAV_SECONDARY_OVERLAYS = ".global-nav-overlay-secondary-menu";
    GlobalNavMobile.SEE_MORE_CONTAINER = ".global-nav-mobile-category-see-container";
    GlobalNavMobile.GLOBAL_NAV_SECONDARY_HEADER_CHEVRON = ".global-nav-overlay-secondary-content-header-chevron";
    GlobalNavMobile.GLOBAL_NAV_TOP_MENU_BUTTON = ".global-nav-overlay-top-menu-button-container";
    GlobalNavMobile.GLOBAL_NAV_SECONDARY_OVERLAY_CATEGORIES = ".global-nav-overlay-secondary-category-items";
    GlobalNavMobile.SECONDARY_MENU_STATE_ATTRIBUTE = "data-state";
    GlobalNavMobile.SECONDARY_MENU_EXPANDED_ATTRIBUTE = "data-expanded";
    return GlobalNavMobile;
}());
export { GlobalNavMobile };
